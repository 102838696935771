import React from 'react'
import {Localization as T} from "../../helpers/Locale";
import PropTypes from 'prop-types'

class TestimonialsItem extends React.PureComponent {

    render = () => {

        const {icon, name, company , content, isLast} = this.props

        // const className = isLast ? 'col_one_third col_last' : 'col_one_third'

        return (
            <React.Fragment>
                <li>
                    <div className="testimonial">
                        <div className="testi-image">
                            <a href="/#">
                                <img src={icon} alt={name} />
                            </a>
                        </div>
                        <div className="testi-content">
                            <p><T>{content}</T></p>
                            <div className="testi-meta">
                                {name}
                                <span>{company}</span>
                            </div>
                        </div>
                    </div>
                </li>
            </React.Fragment>
        )
    }
}

TestimonialsItem.propTypes = {
    name: PropTypes.string.isRequired,
    company: PropTypes.string.isRequired,
    content: PropTypes.string,
    icon: PropTypes.string,
}

export default TestimonialsItem