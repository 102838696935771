import React from 'react'
import {Localization as T} from "../../helpers/Locale";
import PropTypes from 'prop-types'
import FeaturesItem from "./FeaturesItem";

class FeaturesSection extends React.Component {

    render = () => {

        const {title, subtitle, items} = this.props

        return (

            <div className="container clearfix">

                <div id="section-features" className="heading-block title-center page-section">
                    <h2><T>{title}</T></h2>
                    <span><T>{subtitle}</T></span>
                </div>

                {items.map((item, key) => {
                    return <FeaturesItem key={key} title={item.title} subtitle={item.subtitle} icon={item.icon} isLast={key % 3 === 2 && key !== 0} />
                })}

            </div>
        )
    }
}

FeaturesSection.propTypes = {
    items: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
}

export default FeaturesSection