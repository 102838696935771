import React from 'react'
import {Route, Switch} from "react-router-dom";
import MainPage from "./MainPage";

class App extends React.Component{

    render = () => {
        return (
            <Switch>
                <Route
                    exact
                    path={'/'}
                    component={MainPage}/>

                <Route render={() => <h1>Page not found</h1>}/>
            </Switch>
        )
    }
}

export default App