import React from 'react'
import {Localization as T} from '../../../helpers/Locale'
import {connect} from "react-redux";

class Footer extends React.Component {

    render = () => {

        const {
            contact:{phone:contactPhone, email:contactEmail},
            address:{city,street},
        } = this.props

        if(!contactPhone)
            return null

        return (
            <footer id="footer" className="dark">

                <div id="copyrights" style={{background:'url(pics/footer1.jpg) center / cover'}}>

                    <div className="container clearfix">

                        <div style={{display:'flex',justifyContent:'space-between',flexWrap: 'wrap-reverse'}}>

                            <div style={{color:'white'}}>
                                <address>
                                    <strong><T>Our Headquarters</T>:</strong><br />
                                    <T>{street}</T><br />
                                    <T>{city}</T><br />
                                </address>
                                <abbr title="Phone Number"><strong><T>Phone</T>:</strong></abbr>&nbsp;&nbsp;&nbsp;<a href={'tel:'+contactPhone} style={{fontSize:25}}>{contactPhone}</a><br/>
                                <abbr title="Email Address"><strong><T>Email</T>:</strong></abbr> &nbsp;&nbsp;&nbsp;<a href={'email:'+contactEmail} style={{fontSize:25,}}>{contactEmail}</a>
                            </div>

                            <div className="col-xs-hidden">

                                <img style={{height:'20vh'}} src="pics/logo.png" alt="" className="footer-logo" />

                            </div>


                        </div>

                    </div>

                </div>

                <div id="gotoTop" className="icon-angle-up" />
            </footer>
        )
    }
}

export default connect(state => ({
    contact: state.MainData.contact,
    address: state.MainData.address,
}),null)(Footer)