import $ from 'jquery'
export const ADD_DATA = 'ADD_DATA';
export const GET_DATA = 'GET_DATA';
export const RESET_DATA = 'RESET_DATA';

export default function reducer(state={locale:'ru',contact:{},address:{}}, action){
    switch(action.type){
        case ADD_DATA:
            state[action.key] = action.val;

            return Object.assign({},state,state);

        case GET_DATA:
            let res = state[action.key];
            if(res !== undefined)
                return Object.assign({},state, {val:res});

            return state;

        case RESET_DATA:
            state = Object.assign({},state, {locale:'ru'});

            return state;

        default:
            return state;
    }
}

export function addParam(key, val){
    if(key === 'locale'){
        if(['he'].includes(val)){
            $('html').attr('dir','rtl')
            $('html').attr('lang',val)
            $('body').addClass('rtl')

            $('head').after('<link rel="stylesheet" href="css/bootstrap-rtl.css" type="text/css" />')
            $('head').after('<link rel="stylesheet" href="style-rtl.css" type="text/css" />')
            $('head').after('<link rel="stylesheet" href="css/dark-rtl.css" type="text/css" />')
            $('head').after('<link rel="stylesheet" href="css/font-icons-rtl.css" type="text/css" />')
            $('head').after('<link rel="stylesheet" href="css/responsive-rtl.css" type="text/css" />')

            $('head title').text('שיפוצים')
        }else{
            $('html').attr('dir','ltr')
            $('html').attr('lang',val)
            $('body').removeClass('rtl')

            $('link').toArray().filter(l => $(l)[0].outerHTML.match(/-rtl/)).map(i => $(i).remove())

            $('head title').text('Ремонт')
        }
    }

    return {
        type: ADD_DATA,
        key,
        val
    }
}
export function getParam(key){
    return {
        type: GET_DATA,
        key,
    };
}
export function resetData(){
    return {
        type: RESET_DATA,
    }
}