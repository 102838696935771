import React from 'react'
import {Localization as T} from "../../helpers/Locale";
import PropTypes from 'prop-types'
import FBoxItem from "./FBoxItem";
import {connect} from "react-redux";
import {sendMessage} from "../../redux/reducers/EndPointReducer";

class ContactSection extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            form: {
                name:{valid:true,val:'', isRequired:true},
                phone:{valid:true,val:'', isRequired:true},
                email:{valid:true,val:'', isRequired:false},
                subject:{valid:true,val:'', isRequired:false},
                service:{valid:true,val:'', isRequired:false},
                message:{valid:true,val:'', isRequired:false},
            },
        }
    }

    handleSubmit = e => {
        e.preventDefault()
        let {form} = this.state

        Object.keys(form).forEach(key => {
            this._validate(key,form[key].val)
        })

        const isValid = Object.keys(form).filter(key => !form[key].valid).length === 0

        if(isValid){
            this.props.sendMessage(`${form.name.val}\n${form.phone.val}\n${form.email.val}\n${form.subject.val}\n${form.service.val}\n${form.message.val}`)
        }
    }

    _validate = (name, value) => {
        let {form} = this.state
        if(['name','phone'].includes(name)){
            if(value === ''){
                form[name].valid = false
            }
            else if(name === 'phone' && !value.match(/^0[0-9]{8,9}$/)){
                form[name].valid = false
            }
            else
                form[name].valid = true
        }
        this.setState({form})
    }

    handleChange = name => e => {
        let {form} = this.state
        const value = e.target.value

        form[name].val = value
        this._validate(name, value)
    }

    render = () => {

        const {title, subtitle} = this.props
        const {name, phone, email, subject, service, message} = this.state.form
        const {contact:{phone:contactPhone}, address:{city,street}} = this.props

        const fboxes = [
            {title:'Our Headquarters', type:'location', subtitle: city, subtitle2: street, icon:'icon-map-marker2',animation:'bounce'},
            {title:'Speak to Us', type:'phone', subtitle:contactPhone, icon:'icon-phone3',animation:'rubberBand'},
        ]

        const services = [
            {val:'', title:'-- Select One --'},
            {val:'repair', title:'Repair'},
            {val:'paint', title:'Paint'},
            {val:'other', title:'Other'},
        ]

        return (

            <section id="section-contact" className="page-section">

                <div className="heading-block title-center">
                    <h2><T>{title}</T></h2>
                    <span><T>{subtitle}</T></span>
                </div>

                <div className="container clearfix" >

                    <div className="col_half">

                        <div className="fancy-title title-dotted-border">
                            <h3><T>Send us an Email</T></h3>
                        </div>

                        <div className="form-widget">

                            <div className="form-result" />

                            <form className="nobottommargin" name="contactform" noValidate>

                                <div className="form-process" />

                                <div className="col_one_third">
                                    <label htmlFor="contactform-name"><T>Your Name</T></label>
                                    <input type="text" id="contactform-name" name="contactform-name" style={{border:name.valid ? '' : '1px solid red'}}
                                           value={name.val} required onChange={this.handleChange('name')} className="sm-form-control required"/>
                                </div>

                                <div className="col_one_third">
                                    <label htmlFor="contactform-email"><T>Email</T></label>
                                    <input type="email" id="contactform-email"
                                           name="contactform-email" value={email.val} onChange={this.handleChange('email')}
                                           className="required email sm-form-control"/>
                                </div>

                                <div className="col_one_third col_last">
                                    <label htmlFor="contactform-phone"><T>Your Phone</T></label>
                                    <input type="text" id="contactform-phone" required name="contactform-phone" style={{border:phone.valid ? '' : '1px solid red'}}
                                           value={phone.val} onChange={this.handleChange('phone')} className="sm-form-control"/>
                                </div>

                                <div className="clear" />

                                <div className="col_two_third">
                                    <label htmlFor="contactform-subject"><T>Subject</T></label>
                                    <input type="text" id="contactform-subject" name="subject" value={subject.val}
                                           onChange={this.handleChange('subject')}
                                           className="required sm-form-control"/>
                                </div>

                                <div className="col_one_third col_last">
                                    <label htmlFor="contactform-service"><T>Service</T></label>
                                    <select id="contactform-service" name="contactform-service"
                                            className="sm-form-control" value={service.val}
                                            onChange={this.handleChange('service')}>
                                        {services.map((s, key) => <T key={key} option={<option value={s.val} />}>{s.title}</T>)}
                                    </select>
                                </div>

                                <div className="clear" />

                                <div className="col_full">
                                    <label htmlFor="contactform-message"><T>Message</T></label>
                                    <textarea className="required sm-form-control" id="contactform-message"
                                              name="contactform-message" rows="6" cols="30" value={message.val}
                                              onChange={this.handleChange('message')} />
                                </div>

                                <div className="col_full">
                                    <button className="button button-3d nomargin" type="submit"
                                            id="contactform-submit" name="contactform-submit"
                                            onClick={this.handleSubmit}><T>SEND</T>
                                    </button>
                                </div>

                            </form>

                        </div>


                    </div>

                    <div className="col_half col_last">

                        <section id="google-map" className="gmap" style={{height: '410px'}} />

                    </div>

                    <div className="col_full nobottommargin clearfix">

                        {fboxes.map((fb, key) => <FBoxItem animation={fb.animation} count={fboxes.length} type={fb.type} key={key} title={fb.title} subtitle={fb.subtitle} subtitle2={fb.subtitle2} icon={fb.icon} isLast={fboxes.length - 1 === key} />)}

                    </div>

                </div>

            </section>
        )
    }
}

ContactSection.propTypes = {
    // items: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
}

export default connect(state => ({
    address: state.MainData.address,
    contact: state.MainData.contact,
}), (dispatch) => ({
    sendMessage: message => dispatch(sendMessage(message))
}))(ContactSection)