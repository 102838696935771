import React from 'react'
import {Localization as T} from "../../helpers/Locale";
import PropTypes from 'prop-types'

class FBoxItem extends React.PureComponent {

    render = () => {

        const {icon, title, subtitle, subtitle2, isLast, count, animation, type} = this.props
        let layout = 'col_one_fourth'
        if(count === 1)
            layout = ''
        else if(count === 2){
            layout = 'col_half'
        }
        const className = isLast ? layout + ' col_last' : layout

        return (
            <div className={className}>
                <div className="feature-box fbox-center fbox-bg fbox-plain">
                    <div className="fbox-icon">
                        <a href="/#"><i data-animate={animation} className={icon} /></a>
                    </div>
                    <h3><T>{title}</T>
                        <span className="subtitle">
                            {(() => {
                                switch (type) {
                                    case 'phone':
                                        return <a target={'_blank'} style={{fontSize:25}} href={'tel:'+subtitle}><T raw>{subtitle}</T></a>
                                    case 'location':
                                        return <a target={'_blank'} style={{fontSize:25}} href={'http://maps.google.com/?q='+subtitle + ' ' + subtitle2}><T raw>{subtitle}</T> <T raw>{subtitle2}</T></a>

                                    default:
                                        return <span><T>{subtitle}</T> <T>{subtitle2}</T></span>
                                }
                            })()}
                        </span>
                    </h3>
                </div>
            </div>
        )
    }
}

FBoxItem.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    icon: PropTypes.string,
}

export default FBoxItem