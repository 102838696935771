import React from 'react'
import {connect} from "react-redux";
import {Localization as T} from '../../../helpers/Locale'
import {addParam, getParam} from "../../../redux/reducers/MainData";
import {sendMessage} from "../../../redux/reducers/EndPointReducer";
import AlertWidget from "../../AlertWidget/AlertWidget";

class Header extends React.Component{

    constructor(props, context) {
        super(props, context);

        this.state = {
            form: {
                name:'', phone:'', text:''
            },
            alertStatus: 'closed'
        }
    }

    componentDidMount() {
        let locale
        if(navigator.language.indexOf('ru') > -1){
            locale = 'ru'
        }
        else{
            locale = 'he'
        }

        this.props.setInit(
            locale,
            {city:'Bat Yam', street:'Rambam 4'},
            {email:'reis.66@mail.ru', phone:'03-3741416'}
            )
    }

    render = () => {
        let {form} = this.state
        let {success} = this.props

        return (
            <React.Fragment>
            <AlertWidget title={'Success!'} type={'success'} status={success ? 'opened' : 'closed'} />
            <header id="header" className="transparent-header page-section ">

                <div id="header-wrap" style={{opacity:0.9}}>

                    <div className="container" style={{display:'flex', justifyContent:'space-around'}}>

                        <div id="primary-menu-trigger"><i className="icon-reorder" /></div>

                        <div id="logo">
                            <a href="/" className="standard-logo" data-dark-logo="pics/logo.png"><img src="pics/logo.png" alt="Canvas Logo" /></a>
                            <a href="/" className="retina-logo" data-dark-logo="pics/logo.png"><img src="pics/logo.png" alt="Canvas Logo" /></a>
                        </div>

                        <nav id="primary-menu">

                            <ul className="one-page-menu">
                                <li className="current"><a style={{fontSize:20}} href="/#" data-href="#header">
                                    <T raw>Home</T>
                                </a></li>
                                <li><a style={{fontSize:20}} href="/#" data-href="#section-features">
                                    <T raw>Features</T>
                                </a></li>
                                <li><a style={{fontSize:20}} href="/#" data-href="#section-pricing">
                                    <T raw>Pricing</T>
                                </a></li>
                                <li><a style={{fontSize:20}} href="/#" data-href="#section-testimonials">
                                    <T raw>Testimonials</T>
                                </a></li>
                                <li><a style={{fontSize:20}} href="/#" data-href="#section-contact">
                                    <T raw>Contact Us</T>
                                </a></li>
                                <li>
                                    <a href={'/#'} onClick={(e)=>e.preventDefault()}>
                                        <select style={{height:20,padding:0}} value={this.props.locale} onChange={(e)=>{this.props.changeLocale(e.target.value)}} className={'form-control'}>
                                            <option value="he">Hebrew</option>
                                            <option value="ru">Russian</option>
                                        </select>
                                    </a>
                                </li>
                            </ul>

                        </nav>

                    </div>

                </div>

            </header>
            <section id="slider" className="slider-element slider-parallax  full-screen" style={{background: 'url(pics/header1.jpg) center',backgroundSize:'cover'}}>

                <div className="slider-parallax-inner">

                    <div className="container clearfix">

                        <div className="vertical-middle">

                            <div className="heading-block center nobottomborder">
                                <h1 data-animate="fadeInUp" style={{fontSize:65,color:'#1ABC9C',textShadow:'3px 3px 6px #777'}}><T raw>__title</T></h1>
                                <span data-animate="fadeInDown" data-delay="300" style={{fontSize:30,fontWeight:'bold'}}><T>__subtitle</T></span>
                            </div>

                            <form action="/#" method="post" className="landing-wide-form clearfix">
                                <div className="col_four_fifth nobottommargin">
                                    <div className="col_one_third nobottommargin">
                                        <label htmlFor="name" style={{color:'#ccc'}}><T>Your Name</T>
                                            <input id={'name'} type="text" className="form-control form-control-lg not-dark"
                                                   value={form.name} onChange={this.handleChange('name')} />
                                        </label>
                                    </div>
                                    <div className="col_one_third nobottommargin">
                                        <label htmlFor="phone" style={{color:'#ccc'}}><T>Your Phone</T>
                                            <input id={'phone'} type="phone" className="form-control form-control-lg not-dark"
                                                   value={form.phone} onChange={this.handleChange('phone')} />
                                        </label>
                                    </div>
                                    <div className="col_one_third col_last nobottommargin">
                                        <label htmlFor="text" style={{color:'#ccc'}}><T>Your Message</T>
                                            <textarea id={'text'} className="form-control form-control-lg not-dark"
                                                      value={form.text} onChange={this.handleChange('text')} />
                                        </label>
                                    </div>
                                </div>
                                <div className="col_one_fifth col_last nobottommargin">
                                    <label htmlFor="">...
                                        <button className="btn btn-lg btn-danger btn-block nomargin" onClick={this.sendMessage}><T>SEND</T></button>
                                    </label>
                                </div>
                            </form>

                        </div>

                        <a href="/#" data-scrollto="#section-features" className="one-page-arrow">
                            <i className="icon-angle-down infinite animated fadeInDown" />
                        </a>

                    </div>

                </div>

            </section>
            </React.Fragment>
        )
    }

    sendMessage = e => {
        e.preventDefault()

        let {form} = this.state
        const message = `${form.name}\n${form.phone}\n${form.text}`

        this.props.sendMessage(message)
    }

    handleChange = name => e => {
        let {form} = this.state
        form[name] = e.target.value

        this.setState({form})
    }
    handleChangeLocale = e => {
        this.props.changeLocale(e.target.value)
    }
}

export default connect(state => {
    console.log(state)
    return {
        locale: state.MainData.locale,
        loading: state.MainData.loading,
        success: state.MainData.success,
    }
}, (dispatch) => {
    return {
        setInit: (locale, address, contact) => {
            dispatch(addParam('locale', locale))
            dispatch(addParam('address', address))
            dispatch(addParam('contact', contact))
        },
        changeLocale: (locale) => {
            dispatch(addParam('locale', locale))
        },
        sendMessage: (message) => dispatch(sendMessage(message))
    }
})(Header)