import React from 'react';
import reactDom from 'react-dom';
import {createStore, applyMiddleware} from 'redux';
import reducer from "./redux/reducers";
import {Provider} from "react-redux";
import {BrowserRouter as Router} from "react-router-dom";
import App from "./components/App";
import thunk from 'redux-thunk';
import promise from 'redux-promise';

const store = createStore(reducer, applyMiddleware(thunk, promise));

reactDom.render(
    <Provider store={store}>
        <Router basename={'/'}>
            <App />
        </Router>
    </Provider>,
document.getElementById('root'))