import React from "react";

export default {
    'Pricing': 'מחירון',
    'Home': 'ראשי',
    'SEND': 'שלח',
    'Your Name': 'שם מלא*',
    'Your Phone': 'מספר טלפון*',
    'Your Message': 'הודעה שלך*',
    'Features': 'למה אנחנו',
    'FEATURES OVERVIEW': 'אנחנו נותנים יותר',
    'Testimonials': 'המלצות',
    '-- Select One --': '-- תבחר --',
    'Bat Yam, Israel': 'בת ים, ישראל',
    'Send us an Email': 'שלח אמ"ל',
    'Email': 'אמ"ל',
    'Our Headquarters': 'משרד שלנו',
    'Repair': 'שיפוץ',
    'Speak to Us': 'דבר איתנו',
    'Get in Touch with us': 'תיהיה בקשר',
    'Still have Questions? Contact Us using the Form below.': 'נישרו שאלות? צור איתנו קשר בעזרת טופס.',
    'Paint': 'צביעה',
    'Other': 'אחר',
    'Message': 'הודעה',
    'Subject': 'נושא',
    'Service': 'שירות',
    'Some of the Features that are gonna blow your mind off': 'כמה יתרונות שאתם לא תסרבו',
    'Contact Us': 'צור קשר',
    'Low Prise': 'מחירים נמוכים',
    'Our advisers': 'ממליצים עלינו',
    'Working times': 'עמידה בזמנים',
    'Quality work': 'עבודה מצוינת',
    'Phone': 'מס טלפון',
    'Notifications': 'תזכורות',
    'Our Pricing': 'מחירים שלנו',
    'We believe in simple, creative and flexible work standards.': 'אנחנו מעמינים בעבודה פשוטה, מהירה ובסטנדרטים גבוהים.',
    'Flexible & Easy Pricing for wide Audience Groups': 'מחירים זולים וגמישים לכהל הרחב',
    '__low_prise': 'מחירים הכי זולים שאתם יכולים למצוא בשוק',
    '__title': 'הזמן הכי טוב להתחלת שיפוץ!',
    '__subtitle': 'תשלחו הודעה או תתקשרו ואנחנו נדאג למחיר הכי משתלם עבורכם!',
    '__working_times': 'לוח זמנים מתוכנן מראש',
    '__quality_work': 'תוצאות מדהימות בהרבה תחומים: שיפוץ כללי, פינוי פסולת, עבודה נקיה ועוד...',
    '__notifications': 'תזכורות בזמן התקדמות הפרויקט',
    '__oralkin': 'עבודה מעולה, אחרי שיפוץ כללי, החברות השכנות התחילו לבקר אצלינו יותר! עם אחת אנחנו כבר סגרנו פרויקט משותף בפיתוח טכנולוגיה חדשה.',
    '__allakremen': 'ממליצה בחום! עבודה יפה ונקיה! כל הכבוד!',
    '__alexsabantsev': 'עשו שיפוץ מעולה, הכל נקי ומהיר. ממליץ לכולם',
    'Rambam 4': 'רמבם 4',
    'Bat Yam': 'בת ים',
    'Success!': 'נשלח בהצלחה',

    'Very big discounts': 'הנחות גדולות',
    'Contract at the meeting': 'הסכם בפגישה',
    'Big Projects': 'פרויקטים גדולים',
    'Small/Medium Projects': 'פרויקטים קטנים/בינוניים',
}