import React from 'react'
import {Content, Footer, Header, Wrapper} from "./layout";

class MainPage extends React.Component {

    render = () => {
        return (
            <Wrapper>
                <Header />
                <Content />
                <Footer />
            </Wrapper>
        )
    }
}

export default MainPage