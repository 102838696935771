import React from 'react'
import {Localization as T} from "../../helpers/Locale";
import PropTypes from 'prop-types'

class PricingItem extends React.PureComponent {

    render = () => {

        const {title, price, itemNo} = this.props

        return (

            <div style={{width:'50%'}} data-animate={itemNo%2===0 ? 'fadeInLeft' : 'fadeInRight'}>

                <div className="pricing-box">
                    <div className="pricing-title">
                        <h3><T>{title}</T></h3>
                    </div>
                    <div className="pricing-features">
                        <ul>
                            <li><T>{price}</T></li>
                        </ul>
                    </div>
                </div>

            </div>
        )
    }
}

PricingItem.propTypes = {
    title: PropTypes.string.isRequired,
}

export default PricingItem