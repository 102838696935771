import React, {Component} from 'react';

class Wrapper extends Component{

    render = () => {
        const {children} = this.props

        return (
            <div id="wrapper" className="clearfix">
                {children}
            </div>
        )
    }
}

export default Wrapper;