import React, {PureComponent} from "react";
import {connect} from 'react-redux';

import en from './en'
import ru from './ru'
import he from './he'

const locales = {
    en,
    ru,
    he,
}

class Localization extends PureComponent{

    render(){
        const {locale, children, raw, option} = this.props
        this.currentLocale = locale ? locales[locale] : ru

        if(option){
            return <option>{this.t(children)}</option>
        }
        else if(raw)
            return(
                <React.Fragment>
                    {this.t(children)}
                </React.Fragment>
            )
        else
            return(
                <span style={{fontSize: this.isRtl() ? 25 : 20}}>
                    {this.t(children)}
                </span>
            )
    }

    isRtl = () => ['he'].includes(this.props.locale)

    t(key){
        return this.currentLocale[key] ? this.currentLocale[key] : key
    }
}

export default connect((state)=>({
    locale: state.MainData.locale
}))(Localization)