import React from "react";

export default {
    'Pricing': 'Цены',
    'Home': 'Главная',
    'SEND': 'Отправить',
    'Your Name': 'Ваше имя*',
    'Your Phone': 'Тел. номер*',
    'Your Message': 'Ваше сообщение*',
    'Features': 'Мы лучше',
    'Message': 'Сообщение',
    'Subject': 'Тема',
    'Service': 'Сервис',
    'FEATURES OVERVIEW': 'Мы лучше',
    'Testimonials': 'Отзывы',
    'Some of the Features that are gonna blow your mind off': 'Несколько приемуществ, от которых вы не отвернётесь',
    'Contact Us': 'Свяжитесь с нами',
    'Low Prise': 'Низкие цены',
    'Our advisers': 'О нас советуют',
    'Working times': 'Выполнение в запланированные сроки',
    'Quality work': 'Качественная работа',
    '-- Select One --': '-- Выберите --',
    'Notifications': 'Уведомления',
    'Repair': 'Ремонт',
    'We believe in simple, creative and flexible work standards.': 'אנחנו מעמינים בעבודה פשוטה, מהירה ובסטנדרטים גבוהים.',
    'Speak to Us': 'Поговори с нами',
    'Get in Touch with us': 'Свяжись с нами',
    'Still have Questions? Contact Us using the Form below.': 'Остались вопросы? Свяжитесь с нами, используя форму ниже.',
    'Paint': 'Покраска',
    'Other': 'Другое',
    'Our Pricing': 'Наши цены',
    'Phone': 'Телефон',
    'Bat Yam, Israel': 'Бат Ям, Израиль',
    'Send us an Email': 'Отправте нам письмо',
    'Email': 'Эл. почта',
    'Our Headquarters': 'Наш главный офис',
    'Flexible & Easy Pricing for wide Audience Groups': 'Гибкие и простые цены для широкой аудитории',
    '__low_prise': 'Самая низкая цена, которую вы можете найти',
    '__title': 'Самое время для начала нового ремонта!',
    '__subtitle': 'Позвоните или отправте сообщение прямо сейчас и мы позаботимся о вас по самым выгодным ценам!',
    '__working_times': 'Рабочий график запланированный зарание',
    '__quality_work': 'Превосходный результат во многих областях: ремонт/покраска частных квартир и офисов и многое другое...',
    '__notifications': 'Уведомления продвижения проекта',
    '__oralkin': 'Очень хорошая работа, после глобального ремонта, нас чаще навещают соседние фирмы и с одной мы оже заключили договор о производстве новой технологии.',
    '__allakremen': 'Настоятельно рекомендую! Очень чистая и качественная работа! Молодцы!',
    '__alexsabantsev': 'Отлично сделали ремонт, всё чисто и очень быстро. Советую всем',
    'Rambam 4': 'Рамбам 4',
    'Bat Yam': 'Бат Ям',
    'Success!': 'Сообщение отправлено!',
    'Very big discounts': 'По настоящему большие скидки!',
    'Contract at the meeting': 'Договор при встрече',
    'Big Projects': 'Большие проекты',
    'Small/Medium Projects': 'Малые/Средние проекты',

}