import React from 'react'
import {Localization as T} from "../../helpers/Locale";
import PropTypes from 'prop-types'

class FeaturesItem extends React.PureComponent {

    render = () => {

        const {icon, title, subtitle, isLast, isNewRow} = this.props

        const className = isLast ? 'col_one_third col_last' : 'col_one_third'

        return (
            <React.Fragment>
                {isLast ?
                    <React.Fragment>
                        <div className={className}>
                            <div className="feature-box fbox-plain">
                                <div className="fbox-icon" data-animate="bounceIn">
                                    <a href="/#"><i className={icon}/></a>
                                </div>
                                <h3><T>{title}</T></h3>
                                <p><T>{subtitle}</T></p>
                            </div>
                        </div>

                        <div className="clear"/>
                    </React.Fragment>
                    :
                    <div className={className}>
                        <div className="feature-box fbox-plain">
                            <div className="fbox-icon" data-animate="bounceIn">
                                <a href="/#"><i className={icon}/></a>
                            </div>
                            <h3><T>{title}</T></h3>
                            <p><T>{subtitle}</T></p>
                        </div>
                    </div>}
            </React.Fragment>
        )
    }
}

FeaturesItem.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    icon: PropTypes.string,
}

export default FeaturesItem