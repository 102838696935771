import React from 'react'
import PropTypes from 'prop-types'
import styles from './style.css'
import {Localization as T} from '../../helpers/Locale'

class AlertWidget extends React.Component{

    render() {

        const {title,type, status} = this.props
        return (
            <div className={`alert alert-${type} alert-${status}`}>
                <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>
                <i className="icon-ok" /><T>{title}</T>
            </div>
        )
    }
}

AlertWidget.propTypes = {
    title: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['danger','success','info','warning']),
}

export default AlertWidget