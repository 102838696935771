import React from 'react'
import {FeaturesSection} from "../../FeaturesSection";
import {TestimonialsSection} from "../../TestinmonialsSection";
import {PricingSection} from "../../PricingSection";
import {ContactSection} from "../../ContactSection";
import {connect} from "react-redux";

class Content extends React.Component{


    constructor(props, context) {
        super(props, context);
    }

    render = () => {

        const featuresItems = [
            {title: 'Low Prise', subtitle:'__low_prise', icon:'icon-money-bill-alt1'},
            {title: 'Working times', subtitle:'__working_times', icon:'icon-calendar-1'},
            {title: 'Quality work', subtitle:'__quality_work', icon:'icon-thumbs-up1'},
            {title: 'Notifications', subtitle:'__notifications', icon:'icon-chat-3'},
        ]

        const testimonialsItems = [
            {icon:'pics/oralkin.jpg', name:'Or Alkin', company:'Virtual Crypto', content:'__oralkin'},
            {icon:'pics/allakremen.jpg', name:'Alla Kremen', company:'Alonda', content:'__allakremen'},
            {icon:'pics/alexsabantsev.jpg', name:'Alex Sabantsev', company:'Alex repairments', content:'__alexsabantsev'},
        ]

        const pricingItems = [
            {title: 'Big Projects', price:'Contract at the meeting'},
            {title: 'Small/Medium Projects', price:'Very big discounts'},
        ]

        const {contactPhone} = this.props

        return (
            <section id="content">

                <div className="content-wrap">

                    <FeaturesSection items={featuresItems} title={'FEATURES OVERVIEW'} subtitle={'Some of the Features that are gonna blow your mind off'}/>

                    <TestimonialsSection items={testimonialsItems} title={'Testimonials'} subtitle={'Our advisers'}/>

                    <PricingSection title={'Our Pricing'} subtitle={'Flexible & Easy Pricing for wide Audience Groups'} items={pricingItems}/>

                    <ContactSection contactPhone={contactPhone} title={'Get in Touch with us'} subtitle={'Still have Questions? Contact Us using the Form below.'} />

                </div>

            </section>
        )
    }
}

export default connect(state => ({
    contactPhone: state.MainData.contactPhone
}),null)(Content)