import {addParam} from "./MainData";
import axios from 'axios'

const BASE_URL = 'https://akykyfogvj.execute-api.us-east-2.amazonaws.com/dev'
const SEND_MESSAGE = 'SEND_MESSAGE'

export default function reducer(state = {buyOrders:[],payOrders:[]}, action){
    switch (action.type){
        case SEND_MESSAGE:
        {
            const data = action.payload

            if(data && data.result === 'ok'){
                let {additional} = data.data

                state = Object.assign({}, state, {...additional})
            }

            return state
        }

        default:
            return state
    }
}


export function sendMessage(message) {
    return async (dispatch, getState) => {
        let payload = _post('/broadcast', {message, title:'www.sk-oleks.com',action:'sendMessage',channel:'skoleks'}, dispatch)

        dispatch(addParam('loading', true))
        await dispatch({
            type: SEND_MESSAGE,
            payload
        })
        dispatch(addParam('loading', false))
    }
}

async function _post(url, params, dispatch) {
    const resp = await axios.post(BASE_URL + url, params).catch(err => {
        return null
    })

    const {data:{result, message}} = resp

    if(dispatch){
        if(result !== 'ok') {
            dispatch(addParam('error', message))
            dispatch(addParam('error', null))
        }
        else {
            dispatch(addParam('success', true))
            setTimeout(()=>dispatch(addParam('success', null)), 2000)
        }
    }


    return resp
}