import React from 'react'
import {Localization as T} from "../../helpers/Locale";
import PropTypes from 'prop-types'
import TestimonialsItem from "./TestimonialsItem";

class Testimonials extends React.Component {

    render = () => {

        const {title, subtitle, items} = this.props

        return (

            <div className="section">

                <div className="container clearfix">

                    <div id="section-testimonials" className="heading-block title-center page-section">
                        <h2><T>{title}</T></h2>
                        <span><T>{subtitle}</T></span>
                    </div>

                    <ul className="testimonials-grid grid-3 clearfix">

                    {items.map((item, key) => {
                        return <TestimonialsItem key={key} name={item.name} content={item.content} company={item.company} icon={item.icon} />
                    })}

                    </ul>
                </div>

            </div>
        )
    }
}

Testimonials.propTypes = {
    items: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
}

export default Testimonials